










































































import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import moment from 'moment'
import request from '@/utils/request'
const columns = [
    {
        type: 'selection',
        width: 50,
    },
    {
        title: '姓名',
        key: 'name',
    },
    {
        title: '手机号码',
        key: 'mobileNum',
    },
    {
        title: '电话号码',
        key: 'telNum',
    },
    {
        title: '邮箱',
        key: 'email',
    },
    {
        title: '传真号码',
        key: 'faxNum',
    },
    {
        title: '角色名称',
        slot: 'roleId',
    },
    {
        title: '所属区域',
        slot: 'areaCode',
    },
    {
        title: '职务',
        key: 'duty',
    },
    {
        title: '住址',
        key: 'address',
    },
    {
        title: '操作',
        slot: 'action',
        // width: 400
    },
]
export default Vue.extend({
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            columns,
            data: {
                total: 0,
                list: [],
            },
            formData: {},
            roleList: [],
            areaList: [],
            currentToken: '',
            idArr: [] as any,
        }
    },
    mounted() {
        this.currentToken = store.state.token
        Promise.all([
            this.getPublishRole(),
            this.getAreaList(),
        ]).then((e) => {
            this.getData()
        })
    },
    methods: {
        handleSuccess(res: any, file: any) {
            // console.log(res)
            // console.log(file)
            this.getData()
        },
        handleError(res: any) {
            console.log(res)
            this.$Message.error('上传失败，请重试')
            // this.$Message.error(res)
        },
        handleFormatError(file: any) {
            this.$Notice.warning({
                title: '文件格式有误',
                desc: '文件' + file.name + '格式有误, 文档请选择xlsx格式文件',
            });
        },
        exportExcleData() {
            const params = { ...this.formData }
            axios({
                url: '/api/respond/respond_person/export',
                method: 'get',
                params,
                responseType: 'blob',
                headers: {Authorization: this.currentToken},
            }).then((res) => {
                // console.log(res)
                // console.log(res.headers)
                // console.log(res.headers['content-disposition'])
                const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                link.download = '发布对象.csv' // 下载的文件名
                // link.download = '发布对象.xlsx' // 下载的文件名
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }).catch((errorMsg) => {
                this.$Message.error('数据量过大，建议分批导出')
            })
        },
        getPublishRole() {
            return request.get('/api/respond/respond_role/list', null)
            .then((data) => {
                this.roleList = data
            })
        },
        getRoleName(value: any) {
            const current: any =  this.roleList.filter((e: any) => {
                if (e.id === value) {
                    return e
                }
            })
            return current[0].name
        },
        getAreaList() {
            return request.get('/api/sys/area/all_list', null)
            .then((data) => {
                this.areaList = data
            })
        },
        getAreaName(value: any) {
            const current: any =  this.areaList.filter((e: any) => {
                if (e.id === value) {
                    return e
                }
            })
            return current[0].name
        },
        getData() {
            this.loading = true
            const { pageNum, pageSize } = this
            const params = { pageNum, pageSize, ...this.formData }
            request.get('/api/respond/respond_person/page', params)
            .then((data) => {
                this.data = data
                this.loading = false
            })
        },
        selectionALL(selection: any) {
            this.idArr = [] as any
            selection.map((e: any) => {
                this.idArr.push(e.id)
            })
        },
        deleteItem(id: string) {
            if (id === 'more') {
                if (this.idArr.length > 0) {
                    const isStr = this.idArr.join(',')
                    request.del(`/api/respond/respond_person/batch/${isStr}`)
                    .then((res) => {
                        this.$Message.success('删除成功!')
                        this.getData()
                    }).catch((errorMsg) => {
                        this.$Message.error(errorMsg)
                    })
                } else {
                    this.$Message.warning('批量值不能为空')
                }
            } else {
                request.del(`/api/respond/respond_person/${id}`)
                .then((res) => {
                    this.$Message.success('删除成功!')
                    this.getData()
                }).catch((errorMsg) => {
                    this.$Message.error(errorMsg)
                })
            }
        },
        /* 改变pageNum */
        changPageNum(num: number) {
            this.pageNum = num
            this.getData()
        },
    },
})
